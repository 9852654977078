import Swiper from 'swiper/dist/js/swiper.js';

window.onload = ()=>{
	if($('.swiper-slide').length > 1){
		const homeSlide = new Swiper('.swiper-container', {
			speed: 400,
			spaceBetween: 100,
			loop:true,
			effect:'fade',
			fadeEffect: {
				crossFade: true
			},
			autoplay: {
				delay: 5000
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			a11y:{
				prevSlideMessage:'前のスライドへ',
				nextSlideMessage:'次のスライドへ'
			},
			on: {
				init: function(swiper){
					var text = $('.swiper-slide-active').find('.slide-radiousbox-title, .slide-radiousbox-text').text();
					text = text.replace(/\r|\n|\r\n/g, '');
					$('#aria-holder').attr('aria-label',text);
				},
				slideChangeTransitionEnd: function(swiper){
					var text = $('.swiper-slide-active').find('.slide-radiousbox-title, .slide-radiousbox-text').text();
					text = text.replace(/\r|\n|\r\n/g, '');
					$('#aria-holder').attr('aria-label',text);
				}
			}
		});

		//homeSlide.autoplay.stop();

		const controlBtn = $(".swiper-button-control");
		controlBtn.on("click",function(){
			if(controlBtn.hasClass("is-stop")){
				homeSlide.autoplay.start();
				controlBtn.removeClass("is-stop");
				controlBtn.find(".swiper-button-text").text(TEXT_STOP);
				console.log("play");
			}else{
				homeSlide.autoplay.stop();
				controlBtn.addClass("is-stop");
				controlBtn.find(".swiper-button-text").text(TEXT_START);
				console.log("stop");
			}
		});
	}

};

